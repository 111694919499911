import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { margins } from '../home/v2/styledComponents';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

import headerImage from '../../assets/partners/v2/header.image.png';
import headerImageMobile from '../../assets/partners/v2/header.image.mobile.png';

const Container = styled.div`
  font-family: Roboto;
  font-weight: lighter;
  color: ${colors.navy};
  display: flex;
  align-items: center;
  height: 700px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    flex-direction: column;
    margin-bottom: -170px;
  }
`;

const SubContainer = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 150px;
  padding: 58px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    align-items: flex-end;
    padding: 0px;
  }
`;

const Span = styled.span`
  font-size: 22px;
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    width: fit-content;
    margin: auto;
    padding-left: 40px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
  font-size: 22px;
`;

const ImgContainer = styled.div``;
const Img = styled.img`
  position: relative;
  z-index: 1;
`;

const Ul = styled.ul`
  list-style-type: none;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    list-style-type: disc;
    position: relative;
    display: grid;
    top: -170px;
    width: 100vw;
    padding: 185px 0px 30px;
  }
`;

const Li = styled.li`
  margin: 0px 0px ${margins.s};
  &::before {content: '- ';}

  @media (max-width: ${mobileThresholdPixels}) {
    &::before {content: '';}
  }
`;

const Content = () => (
  <Ul>
    <Span>
      <Li>
        <Bold>Divisez par deux</Bold>
        {' '}
        votre taux de retour
      </Li>
      <Li>
        <Bold>Bichonnez</Bold>
        {' '}
        vos clients
      </Li>
      <Li>
        Rentrez dans
        <Bold>l’écosystème responsable</Bold>
        .
      </Li>
    </Span>
  </Ul>
);

const MobileVersion = () => (
  <Container>
    <ImgContainer>
      <Img src={headerImageMobile} alt="la plateforme partenaire Tilli" />
    </ImgContainer>
    <Content />
  </Container>
);

const Header = ({ context: { isMobile } }) => {
  if (isMobile) return <MobileVersion />;
  return (
    <Container>
      <SubContainer>
        <Content />
        <ImgContainer>
          <Img src={isMobile ? headerImageMobile : headerImage} alt="la plateforme partenaire Tilli" />
        </ImgContainer>
      </SubContainer>
    </Container>
  );
};

Header.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(Header);
