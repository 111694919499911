import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import Dots from '../home/v3/Dots';
import { margins, contentWidth } from '../home/v2/styledComponents';
import {
  mobileThresholdPixels, fontSizes, colors, Title2, Subtitle2,
} from '../home/v3/styledComponents';

import shopIcon from '../../assets/partners/v2/shop.icon.png';
import dataIcon from '../../assets/partners/v2/data.icon.png';
import supportIcon from '../../assets/partners/v2/support.icon.png';

const Ul = styled.ul`
  list-style-type: none;
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.s};
    font-size: 14px;
  }
`;

const Li = styled.li`
  margin: 10px 0px;
  &::before {content: '- ';}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const Bold = styled.span`
  font-weight: bold;
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const linkStyle = { color: `${colors.navy}` };

const Blocks = [
  {
    title: 'Couverture du parc boutique',
    icon: shopIcon,
    iconAlt: 'Icon boutique',
    content: (
      <Ul>
        <Li>
          <Bold>Plateforme dédiée de prise de commande</Bold>
          {' '}
          : Mise à disposition d’une solution
          d’accompagnement client pour les vendeurs en boutique.
        </Li>
        <Li>
          <Bold>Brief des équipes de vente</Bold>
          {' '}
          : Homogénéisation du discours vendeur dans
          tout le parc boutique. Mise à disposition de support personnalisé.
        </Li>
        <Li>
          <Bold>Accompagnement et support continu</Bold>
          {' '}
          : Tilli se tient quotidiennement à la disposition
          des vendeurs pour toute question et améliorer l’expérience vendeur/client.
        </Li>
      </Ul>),
  },
  {
    title: 'Board KPI',
    icon: dataIcon,
    iconAlt: 'Icon KPI',
    content: (
      <Ul>
        <Li>
          <Bold>Reporting des typologies de retouche</Bold>
          {' '}
          : Comprendre la morphologie de
          vos clients et ajuster vos futures collections.
        </Li>
        <Li>
          <Bold>Code promos</Bold>
          {' '}
          : Analyse du nombre de codes promo utilisés et le budget dépensé.
        </Li>
        <Li>
          <Bold>Contrôle qualité</Bold>
          {' '}
          : Tableau avec les notes et commentaires clients.
        </Li>
      </Ul>),
  },
  {
    title: 'Customer care',
    icon: supportIcon,
    iconAlt: 'Icon service client',
    content: (
      <Ul>
        <Li>
          <Bold>Éduquez le client sur la page produit</Bold>
          {' '}
          : Informez les clients
          des retouches possibles sur chaque produit.
        </Li>
        <Li>
          <Bold>Communiquez sur le nouveau service</Bold>
          {' '}
          : Page dédiée au fonctionnement de Tilli.
          Communiquer en magasin avec des PLV et des affichages en cabine.
        </Li>
        <Li>
          <Bold>
            {'Complétez l’offre avec notre partenaire Fitle '}
            <Link to={routesMap.TilliXFitle.url} style={linkStyle}>Voir l’article</Link>
          </Bold>
          {' '}
          : Inciter
          vos clients à choisir la bonne taille et on s’occupera des dernières retouches pour un perfect fit.
        </Li>
        <Li>
          <Bold>Bichonnez vos client</Bold>
          {' '}
          : Installé proche des cabines d’essayages d’un évènement,
          un couturier peut apporter une vraie force de vente en conseillant vos clients et
          en épinglant sur place pour leur offrir une expérience sur-mesure.
        </Li>
      </Ul>),
  },
];

const Container = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.s};
  }
`;

const SubContainer = styled.div``;

const BlockContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    margin-bottom: ${margins.l};
  }
`;

const TextContainer = styled.div`
  width: ${(2 * contentWidth) / 3}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Libre Baskerville;
`;

const Title3 = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: 0px;
  padding-left: 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    padding-left: ${margins.s};
  }
`;

const IconContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.s};
  }
`;

const Icon = styled.img``;

const EcoSystemBlock = () => (
  <Container>
    <Title2>Créez un écosystème responsable</Title2>
    <Subtitle2>en ajoutant des briques à l’ensemble du parcours client</Subtitle2>
    <Dots />
    <SubContainer>
      {Blocks.map((block) => (
        <BlockContainer key={block.title}>
          <IconContainer>
            <Icon src={block.icon} alt={block.iconAlt} />
          </IconContainer>
          <TextContainer>
            <TitleContainer>
              <Title3>{block.title}</Title3>
            </TitleContainer>
            {block.content}
          </TextContainer>
        </BlockContainer>
      ))}
    </SubContainer>
  </Container>
);

export default EcoSystemBlock;
