import React, { Component } from 'react';
import {
  ViewPager, Frame, Track, View,
} from 'react-view-pager';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { margins, mobileThresholdPixels } from '../styledComponents';
import routesMap from '../../Routes';
import { getPartnersByKey } from '../home/partnersData';

import arrowLeft from '../../assets/partners/arrow-left.png';
import arrowRight from '../../assets/partners/arrow-right.png';

// cSpell:ignore tilly
const partnerImages = getPartnersByKey({
  bhv: 'service de retouches Lyon Aix Marseille Bordeaux',
  galeries_lafayette: 'broderie t-shirt et pull',
  printemps: 'couturière à Paris, Lyon, Bordeaux et Marseille',
  ellozze: 'combinaisons femmes',
  asphalte: 'atelier retouches',
  madura: 'conciergerie paris',
  apc: 'couturiers certifiés',
  sezane: 'meilleure retoucherie Paris',
  laure_de_sagazan: 'retouche de robe de mariée',
  degres: 'tilly couture',
  admise: 'retouches de tailleur et costume',
  artumes: 'cintrer une veste',
  hast: 'couturiere a domicile tarif',
  jessica_choay: 'retoucheur',
  luz: 'couturiere paris',
  stephanie_wolff: 'conciergerie paris',
  magazelles: 'retouche habit',
  violette_sauvage: 'meilleure retouche paris',
  isabel_marant: 'prestataire de retouche',
});

const Img = styled.img`
  max-width: 200px;
  max-height: 200px;

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 100px;
    max-height: 100px;
  }
`;

const frameStyle = {
  width: '80%',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 150px;
  }
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 40px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
  }
`;

class PartnersPager extends Component {
  render() {
    const viewsToShow = typeof window !== 'undefined' ? Math.ceil(window.innerWidth / 350) : 5;
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <ControlImg
          alt="Partenaire précédent de service de retouches"
          src={arrowLeft}
          onClick={() => this.track.prev()}
        />
        <Frame className="frame" style={frameStyle}>
          <Track
            ref={(c) => { this.track = c; }}
            viewsToShow={viewsToShow}
            infinite
            className="track"
          >
            {partnerImages.map(({ key, img, caption }) => (
              <View className="view" key={key}>
                <Link to={routesMap.PartnersPage.url} style={{ textDecoration: 'none' }}>
                  <ImgContainer>
                    <Img alt={caption} src={img} />
                  </ImgContainer>
                </Link>
              </View>
            ))}
          </Track>
        </Frame>
        <ControlImg
          alt="Partenaire suivant de retouches à domicile"
          src={arrowRight}
          onClick={() => this.track.next()}
        />
      </ViewPager>
    );
  }
}

export default PartnersPager;
